<template>
  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
    <loader v-if="!isContentLoaded" :is-visible="!isContentLoaded"></loader>
    <div v-if="isContentLoaded">
      <div class="iq-card-header d-flex justify-content-between">
        <apex-chart-header :disable-range-selection="true" :header="header" :header-icon="headerIcon"></apex-chart-header>
      </div>
      <div class="card-body iq-card-body p-1" style="position: relative;">
        <div id="chartUniqueId">
          <apexchart v-if="isContentLoaded" type="bar" max-height="750" :options="chartOptions" :colors="colors" :series="series"></apexchart>
        </div>
      </div>
    </div>
  </iq-card>
</template>

<script>
    import Loader from "../../components/shared/Loader";
    import ApexChartHeader from "../../components/apexCharts/ApexChartHeader";

    import ChartService from "../../services/chart";
    import "../../plugins/apexchart";

    export default {
        components: {
            Loader,
            ApexChartHeader
        },
        props: {
            dataUrl: String,
            dateMin: String,
            dateMax: String,
            stacked: Boolean,
            dataObject: Object
        },
        data() {
            return {
                date: { startDate: '', endDate: '' },
                isContentLoaded: false,
                header: '',
                headerIcon: "",
                series: [{
                    name: '',
                    data: []
                }],
                colors: [],
                chartOptions: {
                    chart: {
                        height: 350,
                        type: 'bar',
                        stacked: this.stacked,
                        toolbar: {
                            show: true,
                            offsetX: 0,
                            offsetY: 0,
                            tools: {
                                download: true,
                                selection: false,
                                zoom: false,
                                zoomin: false,
                                zoomout: false,
                                pan: false,
                                reset: false,
                            }
                        },
                        defaultLocale: 'tr',
                        locales: [{
                            name: 'tr',
                            options: {
                                toolbar: {
                                    "exportToSVG": this.$t('DownloadSvg'),
                                    "exportToPNG": this.$t('DownloadPng'),
                                    "menu": this.$t('Menu'),
                                    "selection": this.$t('Selection'),
                                    "selectionZoom": this.$t('SelectionZoom'),
                                    "zoomIn": this.$t('ZoomIn'),
                                    "zoomOut": this.$t('zoomOut'),
                                    "pan": this.$t('Panning'),
                                    "reset": this.$t('ResetZoom')
                                }
                            }
                        }]
                    },
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                position: 'top', // top, center, bottom
                            },
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        offsetY: -20,
                        style: {
                            fontSize: '12px',
                            colors: ["#304758"]
                        }
                    },
                    xaxis: {
                        categories: [],
                        position: 'bottom',
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        crosshairs: {
                            fill: {
                                type: 'gradient',
                                gradient: {
                                    colorFrom: '#D8E3F0',
                                    colorTo: '#BED1E6',
                                    stops: [0, 100],
                                    opacityFrom: 0.4,
                                    opacityTo: 0.5,
                                }
                            }
                        },
                        tooltip: {
                            enabled: true,
                        }
                    },
                    yaxis: {
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false,
                        },
                        labels: {
                            show: false
                        }

                    },
                    title: {
                        floating: true,
                        offsetY: 330,
                        align: 'center',
                        style: {
                            color: '#444'
                        }
                    }
                },
            }
        },
        methods: {
            getData() {
                if (this.dateMin && this.dateMax) {
                    this.url += "?d1=" + this.dateMin + "&d2=" + this.dateMax
                }

                ChartService.getChartData(this.url)
                    .then(response => {
                        if (response) {
                            this.setData(response);
                        }
                });
            },
            setData(filledChartData) {
                let $this = this;
                $this.series = filledChartData.series;
                for (var i = 0; i < $this.series.length; i++) {
                    $this.series[i].name = $this.$t($this.series[i].name);
                }
                $this.header = this.$t(filledChartData.chartHeader);
                $this.headerIcon = filledChartData.chartHeaderIcon ?? "";

                if (filledChartData.colors) {
                    $this.chartOptions.colors = filledChartData.colors;
                }

                $this.chartOptions.xaxis.categories = filledChartData.xaxis.categories;
                $this.isContentLoaded = true;
            }
        },
        watch: {
            date: function (newDate) {
                if (newDate && newDate.length > 0)
                    this.getData()
            },
            chartData: {
                handler: function () {
                    if (this.chartData)
                        this.setData(this.chartData);
                },
                immediate: true
            },
            url: {
                handler: function (value) {
                    if (value)
                        this.getData();
                },
                immediate: true
            }
        },
        computed: {
            chartUniqueId: function () {
                return "apexColumnChart"
            },
            chartData: function () {
                return this.dataObject;
            },
            url: function () {
                return this.dataUrl;
            }
        },
        mounted: function () {
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep .apexcharts-menu-item.exportCSV {
        display: none;
    }

    ::v-deep .apexcharts-menu.apexcharts-menu-open {
        min-width: 82px !important;
    }
</style>
